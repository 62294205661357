import { TripTemplate } from "@/model/trip-template";

export interface TripTemplateReference {
  name: string;
  url: string;
}

export async function getTemplateReference(url: string): Promise<TripTemplateReference> {
  const response = await fetch(url);
  if (!response.ok) throw new Error(`HTTP status: ${response.status}`);
  const template = await response.json() as TripTemplate;
  return {
    name: template.name,
    url,
  };
}

export async function getTemplate(url: string): Promise<TripTemplate> {
  const response = await fetch(url);
  if (!response.ok) throw new Error(`HTTP status: ${response.status}`);
  const template = await response.json() as TripTemplate;
  return template;
}

import { reactive } from 'vue';

export const tripTemplate = reactive({
  currentTemplate: null as unknown as TripTemplate,
});
