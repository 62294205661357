import { trip } from "@/model/trip";

export function evaluateExpression(expression: string) {
  try {
    const f = new Function('days', 'destination', 'conditions', `return (${expression})`);
    return f(trip.days, trip.destination, trip.conditions);
  } catch (err) {
    console.warn(`Error evaluating expression '${expression}':`, err);
    return `{${expression}}`;
  }
}

export function evaluateStringExpression(stringExpression: string) {
  return stringExpression.replaceAll(/\$\{([^}]+)}/g, (match, x) => evaluateExpression(x));
}
