import { Item } from "@/model/item";

export interface Trip {
  destination: string;
  conditions: Record<string, boolean>,
  days: number;
  items: Item[];

  initialize(): void;

  addItem(item: Item): void;

  setCondition(condition: string, value: boolean): void;
  isConditionSatisfied(condition: string, expected: "truthy" | "falsy"): boolean;
}

import { reactive } from 'vue';

export const trip = reactive({
  destination: "",
  conditions: {},
  days: 0,
  items: [],

  initialize() {
    this.destination = "";
    this.conditions = {};
    this.days = 0;
    this.items = [];
  },

  addItem(item: Item) {
    this.items.push(item);
  },

  setCondition(condition: string, value: boolean) {
    this.conditions[condition] = value;
  },

  isConditionSatisfied(condition: string, expected: "truthy" | "falsy") {
    const value = this.conditions[condition];
    if (expected === "truthy") return Boolean(value);
    return !value;
  },
} as Trip);
