
export interface Item {
  label: string;
  plural?: string;
  quantity: number | null;
}

export function itemText(i: Item) {
  return (i.quantity != null && i.quantity > 1) ? `${i.quantity} ${i.plural ?? i.label}` : i.label;
}
